<template>
    <v-container fluid>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>

  

                    <v-col cols="12">
                        <h4>All Messages</h4>
                        <v-data-table
                            :headers="headers"
                            :items="messages"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :items-per-page="5"
                            item-key="email"
                        >
                        <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="getMessageReport(item)"
                            >
                                mdi-chart-bar
                            </v-icon>
                            </template>

                            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                                Your search for "{{ search }}" found no results.
                            </v-alert>
                        </v-data-table>

                    </v-col>

                <template v-if="reportItem!=null">
                    <message-report
                    :item="reportItem"
                    :members="members"
                    />
                </template>

            </template>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment-timezone'
import VueJsonToCsv from 'vue-json-to-csv'
import MessageReport from './MessageReport.vue'
export default {
    components: {
        VueJsonToCsv,
        MessageReport
    },
    data () {
        return {
            search: '',
            headers: [
                { text: 'Sent', align: 'left', sortable: true, value: 'date'},
                { text: 'Subject', align: 'left', sortable: true, value: 'subject'},
                { text: 'Report', value: 'actions', sortable: false },
            ],
            sortBy: "date",
            sortDesc: true,
            reportHeaders: [{text:"Name", value: "name"}, {text:"Value", value:"value"}],
            subjects: [],
            selectedSubject: "Show All",
            loading: false,
            expanded: [],
            messages: [],
            members: [],
            stats: {},
            memberStats: {},
            reportItem: null,
            currentMembershipYear: "2021"
        }
    },
    mounted () {
        this.refresh()
    },
    computed: {

    },
    methods: {
        calendar (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        refresh () {
            this.getMessages()
            this.getMembers()
        },
        getMessages () {
            this.messages = []
            this.loading = true
            FIREBASE.db.collection("emails").where('date', '>', '2020-01-01')
            .get()
            .then( query => {
                query.forEach( doc => {
                    let message = doc.data()
                    message.selected = false
                    this.messages.push(message)
                })
                this.loading = false
            })
            .catch(function(error) {
                console.log(error)
                return []
                this.loading = false
            })
        },
        getMessageReport(item){
            console.log(item)
            this.reportItem = item
        },
        getMembers () { //get all members past and future
            this.loading = true
            this.members = []
            FIREBASE.db.collection("users").where('userType', 'in', ['saguaro']) 
            .get()
            .then( userQuery => {
                userQuery.forEach( userDoc => {
                    this.members.push(userDoc.data())
                })
                this.loading = false
            })
            .catch(function(error) {
                console.log(error)
                return []
                this.loading = false
            })
        },
    }
}
</script>
<style scoped>
.selected {
    background-color: red
}
</style>